<div class="page-title-area page-title-services">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Imágenes</h2>
                <ul>
                    <li><a routerLink="/">Inicio</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Servicios</li>
                </ul>
            </div>
        </div>
    </div>
</div>


<div class="container">
    <div class="row">

            <div class="other-details-item">
                <h1>Servicios de Imágenes</h1>
                <p> En nuestro centro médico ofrecemos servicios de radiología con tecnología avanzada para obtener imágenes de 
                    alta calidad que permiten el diagnóstico preciso de diversas condiciones. Los Rayos X son un método rápido 
                    y no invasivo que se utiliza para examinar distintas áreas del cuerpo y detectar fracturas, infecciones, 
                    problemas en los pulmones, y otras patologías.
                </p>
                <span></span>
                <h2>Exámenes que Realizamos:</h2>
                <ul>
                    <li>Radiografías de Tórax: Utilizadas para evaluar los pulmones, corazón y la estructura del tórax.</li>
                    <li>Radiografías de Huesos: Para detectar fracturas, dislocaciones o enfermedades como la osteoporosis.</li>
                    <li>Radiografías de Abdomen: Ayuda a visualizar órganos internos como los riñones y el tracto digestivo.</li>
                    <li>Radiografías Dentales: Utilizadas para examinar los dientes, mandíbula y estructuras relacionadas.</li>
                    <li>Radiografías de Columna: Para evaluar la columna vertebral en busca de desviaciones, fracturas o problemas degenerativos.</li>
                </ul>
                <h2>Consejos para Realizarse una Radiografía</h2>
                <h2>Uso de Metal y Joyería</h2>
                <p>Se recomienda no llevar objetos metálicos (joyas, relojes, cinturones) en la zona a examinar, ya que estos 
                    pueden interferir con las imágenes.</p>
                <h2>Ropa</h2>
                <p>En algunos casos, es posible que se te pida usar una bata para facilitar el examen. Usa ropa cómoda y fácil 
                    de quitar en caso de ser necesario.</p>
                <h2>Protección contra Radiación</h2>
                <p>En nuestro centro médico tomamos todas las medidas de protección necesarias, como el uso de delantales de 
                    plomo para minimizar la exposición a la radiación, especialmente en pacientes embarazadas o personas que 
                    se realicen múltiples exámenes.</p>
                <h2>Embarazo</h2>
                <p>Si estás embarazada o crees que podrías estarlo, es importante informar al personal médico antes del examen, 
                    ya que se tomarán precauciones especiales o se podrían considerar métodos alternativos.</p>
                <h2>Ventajas de Nuestros Servicios de Rayos X</h2>
                <ul>
                    <li>Resultados rápidos y precisos</li>
                    <li>Tecnología de última generación para obtener imágenes de alta resolución.</li>
                    <li>Equipo médico capacitado para asegurar un proceso seguro y cómodo.</li>
                </ul>
            </div>

    </div>
</div>