import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pediatria',
  templateUrl: './pediatria.component.html',
  styleUrls: ['./pediatria.component.scss']
})
export class PediatriaComponent implements OnInit {
 
  constructor() { }

  ngOnInit(): void {
  }

}
