import { Component, OnInit } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }


  public sendEmail(e: Event) {

    e.preventDefault();
    
//    emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target as HTMLFormElement, 'YOUR_PUBLIC_KEY')
    emailjs.sendForm('default_service', 'template_su3ofbt', e.target as HTMLFormElement, 'CYpE2hjbwEQI4uXIi')

    
      .then((result: EmailJSResponseStatus) => {
        //console.log(result.text); 
        alert("Mensje enviado con exito! 👍");
      }, (error) => {
        //console.log(error.text);
        alert(error.message);
      });
      ;
      
  }

}
