import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-imagenes',
    templateUrl: './imagenes.component.html',
    styleUrls: ['./imagenes.component.scss']
})

export class ImagenesComponent implements OnInit {
    
    constructor() {}

    ngOnInit(): void {
        
    }

}