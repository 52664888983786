<div class="page-title-area page-title-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Agenda tu cita</h2>
                <ul>
                    <li><a routerLink="/">Inicio</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Agendar Citas</li>
                </ul>
            </div>
        </div>
    </div>
</div>



<section class="appointment-area-two">
    <div class="container">
        <div class="row appointment-wrap-two">
            <div class="col-lg-7">
                <div class="appointment-item appointment-item-two">
                    <!--div class="appointment-shape">
                        <img src="assets/img/appointment/3.png" alt="Shape">
                    </div-->

                    <h2>Reserve su cita</h2>
                    <span>Confirmaremos tu cita en 2 horas</span>
                     
                    <div class="appointment-form">
                        <form id="form-citas" (submit)="sendEmail($event)" >
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icon fa-solid fa-user"></i>
                                        <input type="text" name="name" class="form-control" placeholder="Nombre">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icon fa-solid fa-address-card"></i>
                                        <input type="text" name="cedula"  class="form-control" placeholder="Cedula">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icon fa-solid fa-phone"></i>
                                        <input type="text" name="phone_number" class="form-control" placeholder="Teléfono">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icon fa-solid fa-envelope"></i>
                                        <input type="text" name="email" class="form-control" placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icon icofont-doctor"></i>
                                        <select class="form-control" name="doctor">
                                            <option>Seleccione su Doctor</option>
                                            <option>John Smith</option>
                                            <option>Sarah Taylor</option>
                                            <option>Stevn King</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="text-center">
                                        <!--button type="submit" class="btn appointment-btn">Agendar</button-->
                                        <input type="submit" id="button" class="appointment-btn btn-success" value="Enviar" >
                                    </div>
                                </div>
                                
                            </div>

                            <!--div class="text-center" method="post">
                                <button type="submit" class="btn appointment-btn">Submit</button>
                            </div-->
                            
                            <!--?php isset($_POST["nombre"]) ? print $_POST["nombre"] : ""; ?><br-->
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 ">
                <div class="appointment-item-two-right">
                    <div class="appointment-item-content">
                        <h2>Horarios</h2>
                        <div class="content-one">
                            <ul>
                                <li>Lunes</li>
                                <li>Martes</li>
                                <li>Miercoles</li>
                                <li>Jueves</li>
                                <li>Viernes</li>
                                <li>Sabado</li>
                            </ul>
                        </div>
                        <div class="content-two">
                            <ul>
                                <li>9:00 am - 8:00 pm</li>
                                <li>9:00 am - 8:00 pm</li>
                                <li>9:00 am - 8:00 pm</li>
                                <li>9:00 am - 8:00 pm</li>
                                <li>9:00 am - 8:00 pm</li>
                                <li>9:00 am - 8:00 pm</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>