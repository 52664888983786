import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component'; 
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { NosotrosComponent } from './components/pages/nosotros/nosotros.component';
import { AboutComponent } from './components/pages/about/about.component';
import { AppointmentComponent } from './components/pages/appointment/appointment.component';
import { DepartmentsComponent } from './components/pages/departments/departments.component';
import { PediatriaComponent } from './components/pages/departments/pediatria/pediatria.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { SignupComponent } from './components/pages/signup/signup.component';
import { LoginComponent } from './components/pages/login/login.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { DoctorDetailsComponent } from './components/pages/doctor-details/doctor-details.component';
import { DoctorComponent } from './components/pages/doctor/doctor.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ContactanosComponent } from './components/pages/contactanos/contactanos.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { CarouselModule } from 'ngx-owl-carousel-o';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    NavbarComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    AboutComponent,
    AppointmentComponent,
    DepartmentsComponent,
    PediatriaComponent,
    TestimonialsComponent,
    SignupComponent,
    LoginComponent,
    PrivacyPolicyComponent,
    TermsConditionComponent,
    FaqComponent,
    ErrorComponent,
    ServicesComponent,
    ServicesDetailsComponent,
    NosotrosComponent,
    DoctorDetailsComponent,
    DoctorComponent,
    BlogComponent, 
    BlogDetailsComponent,
    ContactComponent,
    ComingSoonComponent,
    ContactanosComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CarouselModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
  
})



export class AppModule { 
 
}
