import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-laboratorio',
    templateUrl: './laboratorio.component.html',
    styleUrls: ['./laboratorio.component.scss']
})

export class LaboratorioComponent implements OnInit {
    
    constructor() {}

    ngOnInit(): void {
        
    }

}