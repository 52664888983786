import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-ecografia',
    templateUrl:'./ecografia.component.html',
    styleUrls: ['./ecografia.component.scss']
})

export class EcografiaComponent implements OnInit {
    
    constructor() {}

    ngOnInit(): void {
        
    }

}