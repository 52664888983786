import { Component, OnInit } from "@angular/core";
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';

@Component({
    selector: 'app-ocupacional',
    templateUrl: './ocupacional.component.html',
    styleUrls: ['./ocupacional.component.scss']
})

export class OcupacionalComponent implements OnInit{

    constructor(){}
    ngOnInit(): void {   
    }

    public sendEmail(e: Event) {

        e.preventDefault();
    
        //    emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target as HTMLFormElement, 'YOUR_PUBLIC_KEY')
        emailjs.sendForm('service_b082keu', 'template_z5ofhuh', e.target as HTMLFormElement, 'CYpE2hjbwEQI4uXIi')
    
        
          .then((result: EmailJSResponseStatus) => {
            //console.log(result.text); 
            alert("Mensaje enviado con exito! 👍");
          }, (error) => {
            //console.log(error.text);
            alert(error.message);
          });
          ;
    
      }
    
}