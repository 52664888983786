<div class="page-title-area page-title-services">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Servicios</h2>
                <ul>
                    <li><a routerLink="/">Inicio</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Servicios</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3 ">
                <div class="service-item">
                    <div class="service-front">
                        <a routerLink="/appointment">
                            <img class="icon" src="assets/img/iconos/services/SVG/medicinageneral.svg">
                        </a>
                        <h3>Consultas Médicas</h3>
                        <p>   </p>
                    </div>

                    <!--div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Consultas Médicas</h3>
                        <p> </p>
                        <a routerLink="/services-details">Read More</a>
                    </div-->
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 " >
                <div class="service-item">
                    <div class="service-front">
                        <a routerLink="/laboratorio">
                            <img class="icon" src="assets/img/iconos/services/SVG/laboratorio.svg">
                        </a>
                        <h3>Laboratorio</h3>
                        <p> </p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 " >
                <div class="service-item">
                    <div class="service-front">
                        <a routerLink="/ecografia">
                            <img class="icon" src="assets/img/iconos/services/SVG/ecografia.svg">
                        </a>
                        <h3>Ecografía</h3>
                        <p></p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 " >
                <div class="service-item">
                    <div class="service-front">
                        <a routerLink="/imagenes">
                            <img class="icon" src="assets/img/iconos/services/SVG/imagenes.svg">
                        </a>
                        <h3>Imágenes</h3>
                        <p></p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 " >
                <div class="service-item">
                    <div class="service-front">
                        <a routerLink="/ocupacional">
                            <img class="icon" src="assets/img/iconos/services/SVG/medicinaocupacional.svg">
                        </a>
                        <h3>Salud Ocupacional</h3>
                        <p></p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="service-item">
                    <div class="service-front">
                        <a routerLink="/appointment">
                            <img class="icon" src="assets/img/iconos/services/SVG/promociones.svg" >
                        </a>
                        <h3>Promociones</h3>
                        <p></p>
                    </div>
                </div>
            </div>



        </div>
    </div>
</section>

<!--section class="expertise-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expertise</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="expertise-item">
                    <div class="row">
                        <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-doctor-alt"></i>
                                    <h3>Certified Doctors</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-stretcher"></i>
                                    <h3>Emergency</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-network"></i>
                                    <h3>Teachnology</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-ambulance-cross"></i>
                                    <h3>Ambulance</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="expertise-item">
                    <div class="expertise-right">
                        <img src="assets/img/home-one/home-bg6.jpg" alt="Expertise">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->

<!--section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Ultimas noticias</h2>
        </div>
        
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg11.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Cemas, Festeja a los pequeños de la casa</a></h3>
                        <p>..</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->
