<div class="page-title-area page-title-services">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Laboratorio</h2>
                <ul>
                    <li><a routerLink="/">Inicio</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Laboratorio</li>
                </ul>
            </div>
        </div>
    </div>
</div>


<div class="container">
    <div class="row">

            <div class="other-details-item">
                <h2>Lunes a Viernes de 6:00 am a 9:00 am</h2>
                <h1>Servicios de Laboratorio</h1>
                <p>Nuestro centro médico ofrece una amplia gama de servicios de laboratorio clínico diseñados para brindar 
                    resultados precisos y confiables. Estos servicios permiten la detección temprana, diagnóstico y monitoreo 
                    de diversas condiciones de salud. Trabajamos con tecnología avanzada y un equipo de profesionales altamente 
                    capacitados para garantizar la máxima calidad en cada análisis.
                </p>
                <span></span>
                <h2>Exámenes Comunes que Realizamos:</h2>
                <ul>
                    <li>Análisis de Sangre: Incluye hemograma completo, perfil lipídico, pruebas de glucosa, entre otros.</li>
                    <li>Pruebas de Orina: Para detectar infecciones, enfermedades renales o metabólicas.</li>
                    <li>Pruebas de Función Hepática y Renal: Evaluación de la salud de estos órganos vitales.</li>
                    <li>Exámenes de Tiroides: Incluye TSH, T3 y T4 para evaluar el funcionamiento de la tiroides.</li>
                    <li>Pruebas de Embarazo y otros exámenes hormonales.</li>
                    <li>Cultivos y Pruebas de Infecciones: Incluye cultivo de garganta, orina, y otros especímenes.</li>
                </ul>
                <h2>Consejos para Realizarse los Exámenes</h2>
                <h2>Análisis de Sangre</h2>
                <p>Ayuno: Es recomendable que algunos exámenes de sangre, como los de glucosa o perfil lipídico, se 
                    realicen con 8 a 12 horas de ayuno. Bebe solo agua durante ese período.
                    Medicamentos: Consulta con tu médico si debes tomar tus medicamentos antes de la prueba o suspender 
                    alguno temporalmente.</p>
                <h2>Pruebas de Orina</h2>
                <p>Para una muestra limpia,  asegúrate de recolectar la primera orina de la mañana y sigue las instrucciones del 
                    personal médico sobre cómo recolectarla correctamente.</p>
                <h2>Pruebas de Función Hepática y Renal</h2>
                <p>Algunas pruebas pueden requerir ayuno o limitación en el consumo de ciertos alimentos el día anterior al examen.</p>
                <h2>Exámenes Especiales</h2>
                <p>Si tienes que realizarte exámenes hormonales, asegúrate de conocer si hay algún requerimiento específico como 
                    realizar la prueba en una fase concreta del ciclo menstrual (en el caso de pruebas hormonales femeninas).</p>
            </div>

    </div>
</div>