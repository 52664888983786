<div class="page-title-area page-title-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Agenda tu cita</h2>
                <ul>
                    <li><a routerLink="/">Inicio</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Citas</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="appointment-area-two">
    <div class="container">
        <div class="row align-items-center appointment-wrap-two">
            <div class="col-lg-7 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="appointment-item appointment-item-two">
                    <div class="appointment-shape">
                        <img src="assets/img/appointment/3.png" alt="Shape">
                    </div>

                    <h2>Reserve su cita</h2>
                    <span>Confirmaremos tu cita en 2 horas</span>
                     
                    <div class="appointment-form">
                        <form id="form-citas" (submit)="sendEmail($event)" >
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-business-man-alt-1"></i>
                                        <label>Nombre</label>
                                        <input type="text" name="name" class="form-control" placeholder="Ingrese su nombre">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-ui-message"></i>
                                        <label>Email</label>
                                        <input type="email" name="email"  class="form-control" placeholder="Ingrese su Email">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-ui-call"></i>
                                        <label>Celular</label>
                                        <input type="text" name="phone_number" class="form-control" placeholder="Ingres su numero celular">
                                    </div>
                                </div>

                                <!--div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-hospital"></i>
                                        <label>Servicio</label>
                                        <select class="form-control" name="servicio">
                                            <option>Dental Care</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>
                                </div-->

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-business-man"></i>
                                        <label>Edad</label>
                                        <input type="text" name="edad" class="form-control" placeholder="Su edad">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-doctor"></i>
                                        <label>Doctor</label>
                                        <select class="form-control" name="doctor">
                                            <option>Seleccione su Doctor</option>
                                            <option>John Smith</option>
                                            <option>Sarah Taylor</option>
                                            <option>Stevn King</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <!--div class="text-center" method="post">
                                <button type="submit" class="btn appointment-btn">Submit</button>
                            </div-->
                            <div class="text-center">
                                <!--button type="submit" class="btn appointment-btn">Agendar</button-->
                                <input type="submit" id="button" class="btn btn-success" value="Agendar" >
                            </div>
                            <!--?php isset($_POST["nombre"]) ? print $_POST["nombre"] : ""; ?><br-->
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="appointment-item-two-right">
                    <div class="appointment-item-content">
                        <h2>Horarios</h2>
                        <div class="content-one">
                            <ul>
                                <li>Lunes</li>
                                <li>Martes</li>
                                <li>Miercoles</li>
                                <li>Jueves</li>
                                <li>Viernes</li>
                                <li>Sabado</li>
                            </ul>
                        </div>
                        <div class="content-two">
                            <ul>
                                <li>9:00 am - 8:00 pm</li>
                                <li>9:00 am - 8:00 pm</li>
                                <li>9:00 am - 8:00 pm</li>
                                <li>9:00 am - 8:00 pm</li>
                                <li>9:00 am - 8:00 pm</li>
                                <li>9:00 am - 8:00 pm</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>