
<div class="home-slider owl-theme owl-carousel">
    <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="common-btn">
                            <a routerLink="/appointment">Agenda tu Cita</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img-h2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="common-btn">
                            <a routerLink="/appointment">Agenda tu Cita</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img-h3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="common-btn">
                            <a routerLink="/appointment">Agenda tu Cita</a>
                            <a routerLink="/departments">Especialidades</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--div class="counter-area">
    <div class="container">
        <div class="row counter-bg">
            <div class="col-sm-6 col-lg-3">
                <div class="emergency-item">
                    <i class="icofont-ui-call"></i>
                    <div class="emergency-inner">
                        <h3>Telefono</h3>
                        <p>+593 993112438</p>
                        <<p>+07 5554 3332 322</p>>
                    </div>
                </div>
            </div>

            <div class="col-sm-6  col-lg-3">
                <div class="emergency-item">
                    <i class="icofont-location-pin"></i>
                    <div class="emergency-inner">
                        <h3>Ubicación</h3>
                        <p>9 de Octubre entre 10 Agosto y Comercio, Santa Elena, Ecuador</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="emergency-item"> 
                    <i class="icofont-email"></i>
                    <div class="emergency-inner">
                        <h3>Email</h3> 
                        <p>info&#64;cemas.ec</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6  col-lg-3">
                <div class="emergency-item">
                    <i class="icofont-ui-clock"></i>
                    <div class="emergency-inner">
                        <h3>Horarios</h3>
                        <p>Lunes - Viernes: 0:00 - 19:00</p>
                        <p>Sabados: 07:00 - 17:00</p>
                    </div>
                </div>
            </div>         

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="emergency-item">
                    <i class="icofont-ambulance-crescent"></i>
                    <div class="emergency-inner">
                        <h3>Ambulance</h3>
                        <p>+07 5554 3332 322</p>
                        <p>+07 5554 3332 322</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div-->

<!--PRINCIPALES SERVICIOS-->

<section class="services-area pb-20 ">
        <div class="section-title pt-50 pb-10" >
            <h1>Principales Servicios</h1>
        </div>

        <div class="container pl-50">
    
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                        <div class="container pt-10">
                            <div class="about-area ">
                                <div class="row " >
                                    <div >
                                        <div class="service-item">
                                            <div class="service-front">
                                                <!--i class="icofont-doctor-alt"></i-->
                                                <a routerLink="/appointment">
                                                    <img class="icon" src="assets/img/iconos/services/SVG/medicinageneral.svg" >
                                                </a>
                                                <h3>Consulta Médica</h3>
                                                <p> </p>
                                            </div>
                                            <!--div class="service-end">
                                                <i class="icofont-doctor"></i>
                                                <p> </p>
                                                <h3>Consulta Médica</h3>
                                                <p> </p>
                                                <a routerLink="/appointment">Mas Información</a>
                                            </div-->
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    
                </ng-template>

                <ng-template carouselSlide>
                        <div class="container pt-10">
                            <div class="about-area">
                                <div class="row">
                                    <div>
                                        <div class="service-item">
                                            <div class="service-front">
                                                <a routerLink="/laboratorio">
                                                    <img class="icon" src="assets/img/iconos/services/SVG/laboratorio.svg"  >
                                                </a>
                                                <h3>Laboratorio</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    
                </ng-template>

                <ng-template carouselSlide>
                        <div class="container pt-10">
                            <div class="about-area ">
                                <div class="row">
                                    <div>
                                        <div class="service-item">
                                            <div class="service-front">
                                                <a routerLink="/ecografia">                                                
                                                    <img class="icon" src="assets/img/iconos/services/SVG/ecografia.svg">
                                                </a>
                                                <h3>Ecografía</h3>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </ng-template>

                <ng-template carouselSlide>
                        <div class="container pt-10">
                            <div class="about-area">
                                <div class="row">
                                    <div>
                                        <div class="service-item">
                                            <div class="service-front">
                                                <a routerLink="/imagenes">
                                                    <img class="icon" src="assets/img/iconos/services/SVG/imagenes.svg">
                                                </a>
                                                <h3>Imagenes</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="container pt-10">
                        <div class="about-area ">
                            <div class="row">
                                <div>
                                    <div class="service-item">
                                        <div class="service-front">
                                            <a routerLink="/ocupacional">
                                                <img class="icon" src="assets/img/iconos/services/SVG/medicinaocupacional.svg">
                                            </a>
                                            <h3>Salud Ocupacional</h3>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                </ng-template>


                <ng-template carouselSlide>
                    <div class="container pt-10">
                        <div class="about-area ">
                            <div class="row">
                                <div>
                                    <div class="service-item">
                                        <div class="service-front">
                                            <img class="icon" src="assets/img/iconos/services/SVG/promociones.svg">
                                            <h3>Promociones</h3>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                </ng-template>


            </owl-carousel-o>
    </div>
</section>



<div class="init-area pb-10">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="init-item">
                    <!--div class="about-left"--> <!--comentado en nueva version!-->
                        <img src="assets/img/home-one/home-bg4.png" alt="About">
                    <!--/div-->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="init-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="About">
                    <h2>En Nuestro Centro de Especialidades</h2>
                        <h1>¡Somos la mejor opción para ver por tu salud!</h1>
                        <p>En centro de especialidades Medicas CEMAS S.A., 
                            vamos a encontrar una solución para mantener tu 
                            bienestar gracias a nuestros médicos especialistas, 
                            por eso estaras en las mejores manos.  
                        </p>
                    
                    
                </div>
            </div>
        </div>
    </div>
</div>


<!--section class="expertise-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expertise</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="expertise-item">
                    <div class="row">
                        <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-doctor-alt"></i>
                                    <h3>Certified Doctors</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-stretcher"></i>
                                    <h3>Emergency</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-network"></i>
                                    <h3>Teachnology</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-ambulance-cross"></i>
                                    <h3>Ambulance</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="expertise-item">
                    <div class="expertise-right">
                        <img src="assets/img/home-one/home-bg6.jpg" alt="Expertise">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->

<!--div class="video-wrap">
    <div class="container-fluid p-0">
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container"> 
                                <div class="video-item">
                                    <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Hospital Introduction</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>About Our Pharmacy</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Our reasearch  center and  lab </h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-icu" role="tabpanel" aria-labelledby="pills-icu-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>CCU & ICU</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-doctor" role="tabpanel" aria-labelledby="pills-doctor-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Our Doctors</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <ul class="video-nav nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item video-nav-item">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="tab" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Hospital Introduction</button>
            </li>

            <li class="nav-item video-nav-item">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="tab" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="true">Pharmacy</button>
            </li>

            <li class="nav-item video-nav-item">
                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="tab" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="true">Reasearch & Lab</button>
            </li>

            <li class="nav-item video-nav-item">
                <button class="nav-link" id="pills-icu-tab" data-bs-toggle="tab" data-bs-target="#pills-icu" type="button" role="tab" aria-controls="pills-icu" aria-selected="true">CCU & ICU</button>
            </li>

            <li class="nav-item video-nav-item">
                <button class="nav-link" id="pills-doctor-tab" data-bs-toggle="tab" data-bs-target="#pills-doctor" type="button" role="tab" aria-controls="pills-doctor" aria-selected="true">Doctors</button>
            </li>
        </ul>
    </div>
</div-->

<!--section class="doctors-area pt-10">
    <div class="section-title pt-50 pb-10" >
        <h2>Nuestros Médicos</h2>
    </div>

        <owl-carousel-o [options]="customOptions">
            
            <ng-template carouselSlide>
                    <div class="container pt-10">
                        <div class="about-area">
                            <div class="row">
                                <div class="doctor-slide">
                                    <a routerLink="/doctor-details" fragment="pediatria" ><img src="assets/img/doctor/dr_01.jpg"></a>

                                </div>
                            </div>
                                  
                        </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="container pt-10">
                    <div class="about-area">
                        <div class="row">
                            <div class="doctor-slide">
                                <a routerLink="/doctor-details" fragment="medicinaInterna" ><img src="assets/img/doctor/dr_02.jpg"></a>
                            </div>
                        </div>
                    </div> 
                </div> 
                
        </ng-template>

        <ng-template carouselSlide>
            <div class="container pt-10">
                <div class="about-area">
                    <div class="row">
                        <div class="doctor-slide">
                            <a routerLink="/doctor-details" ><img src="assets/img/doctor/dr_03.jpg"></a>
                        </div>
                    </div>
                </div> 
            </div> 
        </ng-template>

        <ng-template carouselSlide>
            <div class="container pt-10">
                <div class="about-area">
                    <div class="row">
                        <div class="doctor-slide">
                            <a routerLink="/doctor-details" ><img src="assets/img/doctor/dr_07.jpg"></a>
                        </div>
                    </div>
                </div> 
            </div> 
        </ng-template>
        
        <ng-template carouselSlide>
            <div class="container pt-10">
                <div class="about-area">
                    <div class="row">
                        <div class="doctor-slide">
                            <a routerLink="/doctor-details" ><img src="assets/img/doctor/dr_05.jpg"></a>
                        </div>
                    </div>
                </div> 
            </div> 
    </ng-template>
                     
    </owl-carousel-o>

</section!-->


<!--section class="doctors-area ptb-50">
        <div class="section-title">
            <h2>Conoce a nuestros médicos</h2>
        </div>

        <div class="doctor-slide"> 
            <swiper-container  navigation="true" pagination="true" pagination-clickable="true" space-between="70" slides-per-view="5">
                <swiper-slide class="swiper-slide">
                    <img src="assets/img/doctor/dr_01.jpg" >
                    <div class="capa">   
                        <p routerLink="/doctor-details" fragment="ginecologia">PEDIATRIA</p>    
                    </div>
                                
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                    <img src="assets/img/doctor/dr_02.jpg" alt="">
                    <div class="capa">   
                        <p  routerLink="/doctor-details" fragment="medicinaInterna">MEDICINA INTERNA</p>    
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                    <img src="assets/img/doctor/dr_03.jpg" alt="">
                    <div class="capa">   
                        <p routerLink="/doctor-details" fragment="cardiologia">CARDIOLOGIA</p>    
                    </div>        
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                    <img src="assets/img/doctor/dr_04.jpg" alt="">
                    <div class="capa">   
                        <p routerLink="/doctor-details" fragment="dermatologia">DERMATOLOGIA</p>    
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                    <img src="assets/img/doctor/dr_05.jpg" alt="">
                    <div class="capa">   
                        <p routerLink="/doctor-details" fragment="alergologia">ALERGOLOGIA</p>    
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                    <img src="assets/img/doctor/dr_06.jpg" alt="">
                    <div class="capa">   
                        <p routerLink="/doctor-details" fragment="ginecologia">GINECOLOGIA</p>    
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                    <img src="assets/img/doctor/dr_07.jpg" alt="">
                    <div class="capa">   
                        <p routerLink="/doctor-details" fragment="hematologia">HEMATOLOGIA</p>    
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                    <img src="assets/img/doctor/dr_08.jpg" alt="">
                    <div class="capa">   
                        <p routerLink="/doctor-details" fragment="nutricion">NUTRICION CLINICA</p>    
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                    <img src="assets/img/doctor/dr_09.jpg" alt="">
                    <div class="capa">   
                        <p routerLink="/doctor-details" fragment="obstetricia">OBSTETRICIA</p>    
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                    <img src="assets/img/doctor/dr_10.jpg" alt="">
                    <div class="capa">   
                        <p routerLink="/doctor-details" fragment="oftamologia">OFTAMOLOGIA</p>    
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                    <img src="assets/img/doctor/dr_11.jpg" alt="">
                    <div class="capa">   
                        <p routerLink="/doctor-details" fragment="psicologia">PSICOLOGIA</p>    
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                    <img src="assets/img/doctor/dr_12.jpg" alt="">
                    <div class="capa">   
                        <p routerLink="/doctor-details" fragment="traumatologia">TRAUMATOLOGIA</p>    
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                    <img src="assets/img/doctor/dr_13.jpg" alt="">
                    <div class="capa">   
                        <p routerLink="/doctor-details" fragment="gastroenteorologia">GASTROENTEOROLOGIA</p>   
                         
                    </div>
                </swiper-slide>
                
                ...
            </swiper-container>
        </div>        
    	<div class="container">
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-container mySwiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <img src="assets/img/doctor/dr_01.jpg" alt="">
                        <div class="card-description">
                            <div class="card-title">
                                <h4>Descripción</h4>
                            </div>
                            <div class="card-text">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim possimus dolorum non, modi sunt ips.</p>
                            </div>
                            <div class="card-link">
                              <a href="#">Ver más</a>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <img src="assets/img/doctor/dr_02.jpg" alt="">
                        <div class="card-description">
                            <div class="card-title">
                                <h4>Descripción</h4>
                            </div>
                            <div class="card-text">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum enim possimus dolorum non, modi sunt ips.</p>
                            </div>
                            <div class="card-link">
                                <a href="#">Ver más</a>
                            </div>
                        </div>
                    </div>
                    

                </div>
            </div>
        </div>
        <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/doctor/dr_01.jpg" alt="Doctor">
                        <a routerLink="/appointment">Agenda tu Cita</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dra. Violeta Garcia</a></h3>
                        <span>Pediatra</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/doctor/dr_02.jpg" alt="Doctor">
                        <a routerLink="/appointment">Agenda tu Cita</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Jorge González</a></h3>
                        <span>Médicina Interna</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/doctor/dr_03.jpg" alt="+Doctor">
                        <a routerLink="/appointment">Agenda tu Cita</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Joao Torres</a></h3>
                        <span>Cardiología</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="doctor-btn">
            <a routerLink="/doctor-details">Doctores</a>
        </div>

</section-->

<!--section class = "ptb-50">
    <div class="container" >
        <div class="section-title">
            <h2>Ultimas noticias</h2>
        </div>
        
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <--a routerLink="/blog-details"><img width="367" height="283" src="assets/img/home-one/home-bg11.jpg" alt="Blog"></a>
                        <a routerLink=""><img width="367" height="283" src="assets/img/home-one/home-bg11.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <--h3><a routerLink="/blog-details">Cemas, Festeja a los pequeños de la casa</a></h3>
                        <h3><a>Cemas, Festeja a los pequeños de la casa</a></h3>
                        <p></p>
                        <ul>
                            <--li><a routerLink="/blog-details">Leer  <i class="icofont-long-arrow-right"></i></a></li>
                            <li><a>Leer  <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jul 2, 2023</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->

<section class="contact-area ptb-50">
    <div class="container">
        <div class="section-title">
            <h2>Ubicanos</h2>
        </div>
    </div>
    <div class="contact-map-area">
        <div id="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1993.4039735915178!2d-80.860625061547!3d-2.2258635994386107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902e0983bb61851f%3A0x292983c7854f577c!2sCEMAS%20-%20Centro%20de%20especialidades%20m%C3%A9dicas!5e0!3m2!1ses!2sec!4v1686887702821!5m2!1ses!2sec" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</section>







