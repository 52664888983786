<!--div class="page-title-area page-title-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item-two">
                <h2>Dr. Sarah Taylor</h2>
                <h3>Neurosurgeon.</h3>
                <p>MBBS in Neurology, PHD in Neurosurgeon.</p>
            </div>
        </div>
    </div>
</div-->


<section id="pediatria" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img  src="assets/img/doctor/dr_01.jpg" alt="Doctor">
                    
                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="details-item-two-right">
                            <div class="details-item-content">
                                <div class="content-one">
                                    <ul>
                                        <li>Lunes  </li>
                                        <li>Martes </li>
                                        <li>Miercoles </li>
                                        <li>Jueves    </li>
                                        <li>Viernes   </li>
                                        <li>Sabado    </li>
                                    </ul>
                                </div>
                                <div class="content-two">
                                    <ul>
                                        <li>7h00 - 8h00</li>
                                        <li>7h00 - 8h00</li>
                                        <li>7h00 - 8h00</li>
                                        <li>7h00 - 8h00</li>
                                        <li>7h00 - 8h00</li>
                                        <li>7h00 - 8h00</li>
                                    </ul>
                                </div>
                                <div class="content-three">
                                    <ul>
                                        <li><i class="icofont-ui-call"></i> 593 0993112438</li>
                                        <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>                                        
                                    </ul>
                                    <span> </span>
                                    <p> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dra. Violeta Garcia</h2>
                            <h3>Pediatría</h3>
                            <p>Médico en Pediatría con más de 30 años de experiencia en su rama profesional, especializado en la Universidad de Zulia.
                                Dedicada al cuidado de la salud de los niños, desde que nacen hasta la adolescencia, con una
                                atención de calidez, amor y responsabilidad hacia sus pacientes.
                            </p><br>
                            <h3>Atención en:</h3>
                            <ul>
                                <li> Control del niño sano.</li>
                                <li> Recepción y atención al recién nacido.</li>
                                <li> Enfermedades de lactantes, pres-escolar, escolar y adolescente.</li>
                                <li> Valoración del desarrollo y estado nutricional del niño.</li>
                                <li> Crecimiento y desarrollo de los niños.</li>
                                <li> Prevención de enfermedades.</li>
                                <li> Emergencias Pediátricas.</li>
                            </ul>                        
                        </div>
                    </div>
                    <div class="doctor-details-btn">
                        <a routerLink="/appointment">Agenda tu Cita</a>
                        <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                    </div>
                    </div>
                
            </div>
        </div>
    </div>
</section>


<section id="medicinaInterna" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img  src="assets/img/doctor/dr_02.jpg" alt="Doctor">
                    
                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="details-item-two-right">
                            <div class="details-item-content">
                                <div class="content-one">
                                    <ul>
                                        <li>Lunes  </li>
                                        <li>Martes </li>
                                        <li>Miercoles </li>
                                        <li>Jueves    </li>
                                        <li>Viernes   </li>
                                        <li>Sabado    </li>
                                    </ul>
                                </div>
                                <div class="content-two">
                                    <ul>
                                        <li>7h00 - 8h00</li>
                                        <li>7h00 - 8h00</li>
                                        <li>7h00 - 8h00</li>
                                        <li>7h00 - 8h00</li>
                                        <li>7h00 - 8h00</li>
                                        <li>7h00 - 8h00</li>
                                    </ul>
                                </div>
                                <div class="content-three">
                                    <ul>
                                        <li><i class="icofont-ui-call"></i> 593 0993112438</li>
                                        <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>                                        
                                    </ul>
                                    <span> </span>
                                    <p> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dra. Jorge González</h2>
                            <h3>Medicina Interna</h3>
                            <p>Médico especialista en medicina interna, diagnóstico y trata todas las enfermedades que pueden afectar al adulto,
                                siempre y cuando no necesiten ser tratadas quirúrgicamente.
                            </p><br>
                            <h3>Atención en:</h3>
                            <ul>
                                <li> Control de enfermedades respiratorias.</li>
                                <li> Control de enfermedades cardiovasculares.</li>
                                <li> Endocrinas.</li>
                                <li> Digestivas.</li>
                                <li> Neurológicas y renales.</li>
                                <li> Asesoría anticonceptiva.</li>
                                <li> Electrocardiograma.</li>                                
                            </ul>                        
                        </div>
                    </div>
                    <div class="doctor-details-btn">
                        <a routerLink="/appointment">Agenda tu Cita</a>
                        <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                    </div>
                    </div>
                
            </div>
        </div>
    </div>
</section>


<section id="cardiologia" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img  src="assets/img/doctor/dr_03.jpg" alt="Doctor">
                    <div class="doctor-details-contact">
                        <h3>Contacto</h3>
                        <ul>
                            <li><i class="icofont-ui-call"></i> +5937 554 332 322</li>
                            <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>
                            <!--li><i class="icofont-location-pin"></i>9 de Octubre entre 10 Agosto y Comercio, Santa Elena Ecuador</li-->
                        </ul>
                    </div>
                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="appointment-item-two-right">
                            <div class="appointment-item-content">
                                <div class="content-one">
                                    <ul>
                                        <li>Lunes</li>
                                        <li>Martes</li>
                                        <li>Miercoles</li>
                                        <li>Jueves</li>
                                        <li>Viernes</li>
                                        <li>Sabado</li>
                                    </ul>
                                </div>
                                <div class="content-two">
                                    <ul>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dr. Joao Torres</h2>
                            <h3>Cardiología</h3>
                            <p>Médico en Cardiología especializado en la Universidad FavaloroArgentina. Estudia, diagnóstica y trata,
                                las enfermedades que afectan directamente al corazón y al aparato circulatorio de los pacientes.
                            </p><br>
                            <h3>Atención en:</h3>
                            <ul>
                                <li><i class="icofont-check-circled"></i> Prueba de Esfuerzo, Ergometría.</li>
                                <li><i class="icofont-check-circled"></i> Monitoreo ambulatorio de presión arterial M.A.P.A.</li>
                                <li><i class="icofont-check-circled"></i> Riesgo Prequirúrgicos.</li>
                                <li><i class="icofont-check-circled"></i> Holter de 24 y 48 horas.</li>
                                <li><i class="icofont-check-circled"></i> Electrocardiograma.</li>
                                <li><i class="icofont-check-circled"></i> Ecocardiograma Doppler.</li>
                                <li><i class="icofont-check-circled"></i> Ablación.</li>
                                <li><i class="icofont-check-circled"></i> Cirugías Cardiovasculares.</li>
                                <li><i class="icofont-check-circled"></i> Valoraciones Pre-Quirúrgicas.</li>
                            </ul>                        
                        </div>
                    </div>
                </div>
                <div class="common-btn">
                    <a routerLink="/appointment">Agenda tu Cita</a>
                    <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                </div>

            </div>
        </div>
    </div>
</section>

<section id="dermatologia" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img  src="assets/img/doctor/dr_04.jpg" alt="Doctor">
                    <div class="doctor-details-contact">
                        <h3>Contacto</h3>
                        <ul>
                            <li><i class="icofont-ui-call"></i> +5937 554 332 322</li>
                            <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>
                            <!--li><i class="icofont-location-pin"></i>9 de Octubre entre 10 Agosto y Comercio, Santa Elena Ecuador</li-->
                        </ul>
                    </div>
                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="appointment-item-two-right">
                            <div class="appointment-item-content">
                                <div class="content-one">
                                    <ul>
                                        <li>Lunes</li>
                                        <li>Martes</li>
                                        <li>Miercoles</li>
                                        <li>Jueves</li>
                                        <li>Viernes</li>
                                        <li>Sabado</li>
                                    </ul>
                                </div>
                                <div class="content-two">
                                    <ul>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dra. Bricuyet Marron Mileidy</h2>
                            <h3>Dermatología</h3>
                            <p>Médico especialista de primer grado en Dermatología, con más de 20 años de experiencia en su rama profesional,
                                graduada del Instituto Superior de Ciencias Médicas de Santiago de Cuba.
                                Médico encargado de las diversas enfermedades que afectan la piel, trata y previene.
                            </p><br>
                            <h3>Atención en:</h3>
                            <ul>
                                <li><i class="icofont-check-circled"></i> Control médico Integral.</li>
                                <li><i class="icofont-check-circled"></i> Curaciones avanzadas.</li>
                                <li><i class="icofont-check-circled"></i> Procedimientos de cirugía menor.</li>
                                <li><i class="icofont-check-circled"></i> Internación de exámenes.</li>
                                <li><i class="icofont-check-circled"></i> Asesoría anticonceptiva.</li>
                                <li><i class="icofont-check-circled"></i> Suturas.</li>
                                <li><i class="icofont-check-circled"></i> Ecografías generales.</li>
                            </ul>                        
                        </div>
                    </div>
                </div>
                <div class="common-btn">
                    <a routerLink="/appointment">Agenda tu Cita</a>
                    <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                </div>

            </div>
        </div>
    </div>
</section>


<section id="alergologia" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img  src="assets/img/doctor/dr_05.jpg" alt="Doctor">
                    <div class="doctor-details-contact">
                        <h3>Contacto</h3>
                        <ul>
                            <li><i class="icofont-ui-call"></i> +5937 554 332 322</li>
                            <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>
                            <!--li><i class="icofont-location-pin"></i>9 de Octubre entre 10 Agosto y Comercio, Santa Elena Ecuador</li-->
                        </ul>
                    </div>
                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="appointment-item-two-right">
                            <div class="appointment-item-content">
                                <div class="content-one">
                                    <ul>
                                        <li>Lunes</li>
                                        <li>Martes</li>
                                        <li>Miercoles</li>
                                        <li>Jueves</li>
                                        <li>Viernes</li>
                                        <li>Sabado</li>
                                    </ul>
                                </div>
                                <div class="content-two">
                                    <ul>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dra. Damelis Martinez</h2>
                            <h3>Alergología</h3>
                            <p>Médico especialista en Alergología, tratar, diagnostica y dota la información de los pacientes, para así
                                combatir cualquier tipo de alergia que afecte a los diversos sistemas del organismo.
                            </p><br>
                            <h3>Atencións en:</h3>
                            <ul>
                                <li><i class="icofont-check-circled"></i> Alergias cutáneas.</li>
                                <li><i class="icofont-check-circled"></i> Dermatitis atópica.</li>
                                <li><i class="icofont-check-circled"></i> Alergias respiratorias (rinitis, asma).</li>
                                <li><i class="icofont-check-circled"></i> Alergias respiratorias y alimentarias.</li>
                                <li><i class="icofont-check-circled"></i> Fortalecimiento del sistema inmune</li>
                                <li><i class="icofont-check-circled"></i> Lupus, artritis, reumatoide, psoriasis.</li>
                                <li><i class="icofont-check-circled"></i> Lupus, artritis, reumatoide, psoriasis</li>
                                <li><i class="icofont-check-circled"></i> Prick test</li>
                            </ul>                        
                        </div>
                    </div>
                </div>
                <div class="common-btn">
                    <a routerLink="/appointment">Agenda tu Cita</a>
                    <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                </div>

            </div>
        </div>
    </div>
</section>

<section id="ginecologia" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img  src="assets/img/doctor/dr_06.jpg" alt="Doctor">
                    <div class="doctor-details-contact">
                        <h3>Contacto</h3>
                        <ul>
                            <li><i class="icofont-ui-call"></i> +5937 554 332 322</li>
                            <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>
                            <!--li><i class="icofont-location-pin"></i>9 de Octubre entre 10 Agosto y Comercio, Santa Elena Ecuador</li-->
                        </ul>
                    </div>
                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="appointment-item-two-right">
                            <div class="appointment-item-content">
                                <div class="content-one">
                                    <ul>
                                        <li>Lunes</li>
                                        <li>Martes</li>
                                        <li>Miercoles</li>
                                        <li>Jueves</li>
                                        <li>Viernes</li>
                                        <li>Sabado</li>
                                    </ul>
                                </div>
                                <div class="content-two">
                                    <ul>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dra. Monica Alvarado</h2>
                            <h3>Ginecología</h3>
                            <p>Médico especialista en ginecología, trata las patologías relacionadas con los órganos femeninos
                                como el útero, la vagina y los ovarios, diagnostica y previene las enfermedades de los pacientes.
                            </p><br>
                            <h3>Atención en:</h3>
                            <ul>
                                <li><i class="icofont-check-circled"></i> Consultas ginecológicas y obstétricas de alto riesgo.</li>
                                <li><i class="icofont-check-circled"></i> Prevención del cáncer y cuello uterino.</li>
                                <li><i class="icofont-check-circled"></i> Tratamiento de fertilidad.</li>
                                <li><i class="icofont-check-circled"></i> Climaterio.</li>
                                <li><i class="icofont-check-circled"></i> Menopausia.</li>
                                <li><i class="icofont-check-circled"></i> Planificación familiar.</li>
                            </ul>                        
                        </div>
                    </div>
                </div>
                <div class="common-btn">
                    <a routerLink="/appointment">Agenda tu Cita</a>
                    <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                </div>
            </div>
        </div>
    </div>
</section>


<section id="hematologia" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img  src="assets/img/doctor/dr_07.jpg" alt="Doctor">
                    <div class="doctor-details-contact">
                        <h3>Contacto</h3>
                        <ul>
                            <li><i class="icofont-ui-call"></i> +5937 554 332 322</li>
                            <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>
                            <!--li><i class="icofont-location-pin"></i>9 de Octubre entre 10 Agosto y Comercio, Santa Elena Ecuador</li-->
                        </ul>
                    </div>
                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="appointment-item-two-right">
                            <div class="appointment-item-content">
                                <div class="content-one">
                                    <ul>
                                        <li>Lunes</li>
                                        <li>Martes</li>
                                        <li>Miercoles</li>
                                        <li>Jueves</li>
                                        <li>Viernes</li>
                                        <li>Sabado</li>
                                    </ul>
                                </div>
                                <div class="content-two">
                                    <ul>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dra. Amel Guanchéz</h2>
                            <h3>Hematología</h3>
                            <p>Médico especialista en Hematología, en donde se encarga de diagnosticar, prevenir y tratar las
                                enfermedades relacionadas con la sangre, la médula ósea, el bazo y los ganglios linfáticos.
                            </p><br>
                            <h3>Atención en:</h3>
                            <ul>
                                <li><i class="icofont-check-circled"></i> Diagnóstico y prevención oportuna de la sangre.</li>
                                <li><i class="icofont-check-circled"></i> Alteraciones de glóbulos rojos (Anemias Policitemias).</li>
                                <li><i class="icofont-check-circled"></i> Alteraciones en plaquetas trombocitopenias, trombocitosis.</li>
                                <li><i class="icofont-check-circled"></i> Alteraciones de glóbulos blancos (Leucemias, Linfomas, mielomas). </li>
                                <li><i class="icofont-check-circled"></i> Alteraciones de coagulación.</li>
                                <li><i class="icofont-check-circled"></i> Aplasias.</li>
                            </ul>                        
                        </div>
                    </div>
                </div>
                <div class="common-btn">
                    <a routerLink="/appointment">Agenda tu Cita</a>
                    <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                </div>

            </div>
        </div>
    </div>
</section>

<section id="nutricion" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img  src="assets/img/doctor/dr_08.jpg" alt="Doctor">
                    <div class="doctor-details-contact">
                        <h3>Contacto</h3>
                        <ul>
                            <li><i class="icofont-ui-call"></i> +5937 554 332 322</li>
                            <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>
                            <!--li><i class="icofont-location-pin"></i>9 de Octubre entre 10 Agosto y Comercio, Santa Elena Ecuador</li-->
                        </ul>
                    </div>
                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="appointment-item-two-right">
                            <div class="appointment-item-content">
                                <div class="content-one">
                                    <ul>
                                        <li>Lunes</li>
                                        <li>Martes</li>
                                        <li>Miercoles</li>
                                        <li>Jueves</li>
                                        <li>Viernes</li>
                                        <li>Sabado</li>
                                    </ul>
                                </div>
                                <div class="content-two">
                                    <ul>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dra. Monica Yepez</h2>
                            <h3>Nutrición Clínica</h3>
                            <p>Médico especialista en nutrición clínica y pediátrica, encargada de brindar asistencia sanitaria a 
                                los pacientes para que puedan llevar una alimentación sana y saludable, estudiando en sí, los 
                                hábitos alimenticios de cada uno de los pacientes.
                            </p><br>
                            <h3>Atención en:</h3>
                            <ul>
                                <li><i class="icofont-check-circled"></i> Abordaje integral de enfermedades crónicas:
                                    Diabetes, prediabetes, obesidad, sobrepeso, hiperlipidemias..</li>
                                <li><i class="icofont-check-circled"></i> Acompañamiento nutricional en cirugía pre y post bariátrica.</li>
                                <li><i class="icofont-check-circled"></i> Aumento de masa muscular.</li>
                                <li><i class="icofont-check-circled"></i> Educación nutricional y hábitos saludables.</li>
                            </ul>                        
                        </div>
                    </div>
                </div>
                <div class="common-btn">
                    <a routerLink="/appointment">Agenda tu Cita</a>
                    <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                </div>

            </div>
        </div>
    </div>
</section>


<section id="obstetricia" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img  src="assets/img/doctor/dr_09.jpg" alt="Doctor">
                    <div class="doctor-details-contact">
                        <h3>Contacto</h3>
                        <ul>
                            <li><i class="icofont-ui-call"></i> +5937 554 332 322</li>
                            <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>
                            <!--li><i class="icofont-location-pin"></i>9 de Octubre entre 10 Agosto y Comercio, Santa Elena Ecuador</li-->
                        </ul>
                    </div>
                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="appointment-item-two-right">
                            <div class="appointment-item-content">
                                <div class="content-one">
                                    <ul>
                                        <li>Lunes</li>
                                        <li>Martes</li>
                                        <li>Miercoles</li>
                                        <li>Jueves</li>
                                        <li>Viernes</li>
                                        <li>Sabado</li>
                                    </ul>
                                </div>
                                <div class="content-two">
                                    <ul>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dra. Katherine Merejildo</h2>
                            <h3>Obstetricia</h3>
                            <p> Médico especializada en Obstetricia, encargada de precautelar la fase del embarazo, el
                                nacimiento y el puerperio de la paciente, incluyendo a su vez las situaciones de
                                riesgos que requieran una intervención quirúrgica.
                            </p><br>
                            <h3>Atención en:</h3>
                            <ul>
                                <li><i class="icofont-check-circled"></i> Diagnóstico y control de embarazo.</li>
                                <li><i class="icofont-check-circled"></i> Control prenatal y postparto.</li>
                                <li><i class="icofont-check-circled"></i> Planificación familiar.</li>
                                <li><i class="icofont-check-circled"></i> Infecciones vaginales y de transmisión sexual.</li>
                                <li><i class="icofont-check-circled"></i> Infecciones de vías urinarías.</li>
                                <li><i class="icofont-check-circled"></i> Control de premenopausia y menopausia.</li>
                                <li><i class="icofont-check-circled"></i> Realización de Papanicolau y autoexamen de mamas.</li>
                                <li><i class="icofont-check-circled"></i> Procedimientos obstétricos y ginecológicos.</li>

                            </ul>                        
                        </div>
                    </div>
                </div>
                <div class="common-btn">
                    <a routerLink="/appointment">Agenda tu Cita</a>
                    <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                </div>

            </div>
        </div>
    </div>
</section>

<section id="oftamologia" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img  src="assets/img/doctor/dr_10.jpg" alt="Doctor">
                    <div class="doctor-details-contact">
                        <h3>Contacto</h3>
                        <ul>
                            <li><i class="icofont-ui-call"></i> +5937 554 332 322</li>
                            <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>
                            <!--li><i class="icofont-location-pin"></i>9 de Octubre entre 10 Agosto y Comercio, Santa Elena Ecuador</li-->
                        </ul>
                    </div>
                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="appointment-item-two-right">
                            <div class="appointment-item-content">
                                <div class="content-one">
                                    <ul>
                                        <li>Lunes</li>
                                        <li>Martes</li>
                                        <li>Miercoles</li>
                                        <li>Jueves</li>
                                        <li>Viernes</li>
                                        <li>Sabado</li>
                                    </ul>
                                </div>
                                <div class="content-two">
                                    <ul>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dra. Gil Yailin</h2>
                            <h3>Oftamología</h3>
                            <p> Médico especialista en Oftalmología, diagnostica y trata todas las enfermedades oculares
                                de los pacientes, prescribe lentes para corregir problemas de visión en niños y adultos.
                            </p><br>
                            <h3>Atención en:</h3>
                            <ul>
                                <li><i class="icofont-check-circled"></i> Tratamiento en enfermedades oculares.</li>
                                <li><i class="icofont-check-circled"></i> Exámenes oftalmológicos.</li>
                                <li><i class="icofont-check-circled"></i> Tratamiento de conjuntivitis.</li>
                                <li><i class="icofont-check-circled"></i> Extracción de cuerpos extraños.</li>
                                <li><i class="icofont-check-circled"></i> Fondo de ojo.</li>
                                <li><i class="icofont-check-circled"></i> Cirugías Oftalmológicas.</li>
                            </ul>                        
                        </div>
                    </div>
                </div>
                <div class="common-btn">
                    <a routerLink="/appointment">Agenda tu Cita</a>
                    <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                </div>

            </div>
        </div>
    </div>
</section>

<section id="psicologia" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img  src="assets/img/doctor/dr_11.jpg" alt="Doctor">
                    <div class="doctor-details-contact">
                        <h3>Contacto</h3>
                        <ul>
                            <li><i class="icofont-ui-call"></i> +5937 554 332 322</li>
                            <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>
                            <!--li><i class="icofont-location-pin"></i>9 de Octubre entre 10 Agosto y Comercio, Santa Elena Ecuador</li-->
                        </ul>
                    </div>
                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="appointment-item-two-right">
                            <div class="appointment-item-content">
                                <div class="content-one">
                                    <ul>
                                        <li>Lunes</li>
                                        <li>Martes</li>
                                        <li>Miercoles</li>
                                        <li>Jueves</li>
                                        <li>Viernes</li>
                                        <li>Sabado</li>
                                    </ul>
                                </div>
                                <div class="content-two">
                                    <ul>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dra. Ariana Garnica</h2>
                            <h3>Psicología</h3>
                            <p> Médico especialista en Psicología, dedicada al estudio, investigación y comprensión de la
                                estructura psíquica de los pacientes, estableciendo el psicodiagnóstico y el tratamiento de los
                                aspectos psicopatológicos de los mismos en todas las dimensiones.
                            </p><br>
                            <h3>Atención en:</h3>
                            <ul>
                                <li><i class="icofont-check-circled"></i> Psicodiagnóstico.</li>
                                <li><i class="icofont-check-circled"></i> Terapias.</li>
                                <li><i class="icofont-check-circled"></i> Evaluación de orientación vocacional.</li>
                                <li><i class="icofont-check-circled"></i> Técnicas cognitivas.</li>
                                <li><i class="icofont-check-circled"></i> Informes psicológicos.</li>                                
                            </ul>                        
                        </div>
                    </div>
                </div>
                <div class="common-btn">
                    <a routerLink="/appointment">Agenda tu Cita</a>
                    <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                </div>

            </div>
        </div>
    </div>
</section>

<section id="traumotologia" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img  src="assets/img/doctor/dr_12.jpg" alt="Doctor">
                    <div class="doctor-details-contact">
                        <h3>Contacto</h3>
                        <ul>
                            <li><i class="icofont-ui-call"></i> +5937 554 332 322</li>
                            <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>
                            <!--li><i class="icofont-location-pin"></i>9 de Octubre entre 10 Agosto y Comercio, Santa Elena Ecuador</li-->
                        </ul>
                    </div>
                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="appointment-item-two-right">
                            <div class="appointment-item-content">
                                <div class="content-one">
                                    <ul>
                                        <li>Lunes</li>
                                        <li>Martes</li>
                                        <li>Miercoles</li>
                                        <li>Jueves</li>
                                        <li>Viernes</li>
                                        <li>Sabado</li>
                                    </ul>
                                </div>
                                <div class="content-two">
                                    <ul>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dr. Harold Secaira</h2>
                            <h3>Traumotología</h3>
                            <p> Médico en Traumatología y Ortopedia, especializado en el Hospital Alcívar, trata diferentes 
                                lesiones del sistema psicomotor como es el caso de huesos y músculos, pionero en
                                cirugías Traumatólogas.
                            </p><br>
                            <h3>Atención en:</h3>
                            <ul>
                                <li><i class="icofont-check-circled"></i> Tratamiento de fracturas, desguinces y desgarros
                                    musculares.</li>
                                <li><i class="icofont-check-circled"></i> Terapias de plasmas rico en plaquetas para artrosis y
                                    tendinitis.</li>
                                <li><i class="icofont-check-circled"></i> Artroscopias.</li>
                                <li><i class="icofont-check-circled"></i> Prótesis.</li>
                                <li><i class="icofont-check-circled"></i> Tratamientos de Lesiones deportivas.</li>
                                <li><i class="icofont-check-circled"></i> Infiltraciones.</li>
                                <li><i class="icofont-check-circled"></i> Cirugías Traumatólogas.</li>
                            </ul>                        
                        </div>
                    </div>
          |      </div>
                <div class="common-btn">
                    <a routerLink="/appointment">Agenda tu Cita</a>
                    <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                </div>

            </div>
        </div>
    </div>
</section>

<section id="gastroenteorologia" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img  src="assets/img/doctor/dr_13.jpg" alt="Doctor">
                    <div class="doctor-details-contact">
                        <h3>Contacto</h3>
                        <ul>
                            <li><i class="icofont-ui-call"></i> +5937 554 332 322</li>
                            <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>
                            <!--li><i class="icofont-location-pin"></i>9 de Octubre entre 10 Agosto y Comercio, Santa Elena Ecuador</li-->
                        </ul>
                    </div>
                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="appointment-item-two-right">
                            <div class="appointment-item-content">
                                <div class="content-one">
                                    <ul>
                                        <li>Lunes</li>
                                        <li>Martes</li>
                                        <li>Miercoles</li>
                                        <li>Jueves</li>
                                        <li>Viernes</li>
                                        <li>Sabado</li>
                                    </ul>
                                </div>
                                <div class="content-two">
                                    <ul>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dr. Carlos Ramírez</h2>
                            <h3>Gastroenteorología</h3>
                            <p> Médico especialista en Gastroenterología que mantiene la capacitación y la experiencia dedicada
                                al manejo de enfermedades del tracto gastrointestinal y el hígado.
                            </p><br>
                            <h3>Atención en:</h3>
                            <ul>
                                <li><i class="icofont-check-circled"></i> Diagnóstico y tratamiento de enfermedades gastrointestinales.</li>
                                <li><i class="icofont-check-circled"></i> Gastritis.</li>
                                <li><i class="icofont-check-circled"></i> Ulcera gástrica y duodenal.</li>
                                <li><i class="icofont-check-circled"></i> Cáncer gástrico.</li>
                                <li><i class="icofont-check-circled"></i> Hernias hiatales.</li>
                                <li><i class="icofont-check-circled"></i> Hepatitis.</li>
                                <li><i class="icofont-check-circled"></i> Hígado graso.</li>
                                <li><i class="icofont-check-circled"></i> Enfermedades del páncreas.</li>
                                <li><i class="icofont-check-circled"></i> Enfermedades del colon.</li>                                                                
                                <li><i class="icofont-check-circled"></i> Enfermedades inflamatorias intestinales.</li>                                                                
                                <li><i class="icofont-check-circled"></i> Pólipos colónicos.</li>                                                                
                            </ul>                        
                        </div>
                    </div>
                </div>
                <div class="common-btn">
                    <a routerLink="/appointment">Agenda tu Cita</a>
                    <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                </div>

            </div>
        </div>
    </div>
</section>

<!--div class="appointment-area-three">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-7">
                <div class="appointment-item appointment-item-two">
                    <div class="appointment-shape">
                        <img src="assets/img/appointment/3.png" alt="Shape">
                    </div>

                    <h2>Book your appointment</h2>
                    <span>We will confirm your  appointment within 2 hours</span>

                    <div class="appointment-form">
                        <form>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-business-man-alt-1"></i>
                                        <label>Name</label>
                                        <input type="text" class="form-control" placeholder="Enter Your Name">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-ui-message"></i>
                                        <label>Email</label>
                                        <input type="email" class="form-control" placeholder="Enter Your Email">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-ui-call"></i>
                                        <label>Phone</label>
                                        <input type="text" class="form-control" placeholder="Enter Your Number">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-hospital"></i>
                                        <label>Services</label>
                                        <select class="form-control">
                                            <option>Dental Care</option>
                                            <option>Pathology</option>
                                            <option>Diagnosis</option>
                                            <option>Neurology</option>
                                            <option>Cardiology</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-doctor"></i>
                                        <label>Doctor</label>
                                        <input type="text" class="form-control" placeholder="Choose Your Doctor">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-business-man"></i>
                                        <label>Age</label>
                                        <input type="text" class="form-control" placeholder="Your Age">
                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn appointment-btn">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right speciality-right-two speciality-right-three">
                    <img src="assets/img/doctor/4.jpg" alt="Doctor">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3>Emergency Call</h3>
                        <p>+07 554 332 322</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div-->