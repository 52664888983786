<!--div class="header-top">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-sm-9 col-lg-9">
                <div class="header-top-item">
                    <div class="header-top-left">
                        <ul>
                            <li><a href="tel:+593993112438"><i class="icofont-ui-call"></i> +593 993112438</a></li>
                            <li><a href="mailto:info@cemas.ec"><i class="icofont-ui-message"></i> info@cemas.ec</a>
                            </li>
                            <li><i class="icofont-location-pin"></i> 9 de Octubre entre 10 Agosto y Comercio, Santa Elena Ecuador </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3">
                <div class="header-top-item">
                    <div class="header-top-right">
                        <ul>
                            <li><a href="https://www.instagram.com/cemas_ec/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div-->

<div class="navbar-area sticky-top">
    <link rel="stylesheet" href="https://unpkg.com/swiper/swiper-bundle.min.css" />
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo-two.png" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="#services" class="nav-link">Servicios</a></li>
                        <li class="nav-item"><a href="#departments" class="nav-link">Especialidades</a></li>
                        <li class="nav-item"><a href="#department" class="nav-link">Promociones</a></li>
                        <li class="nav-item"><a href="#department" class="nav-link">Convenios</a></li>
                        <li class="nav-item"><a href="#nosotros" class="nav-link">Nosotros</a></li>

                        <!--li class="nav-item"><a routerLink="/nosotros" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Nosotros</a></li-->
                        <!--li class="nav-item dropdown"  >
                            <a class="nav-link dropdown-toggle">Servicios</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a class="dropdown-item" href="#appointment" class="nav-link" >Citas</a></li>                                
                                <li class="nav-item"><a class="dropdown-item" href="#services" class="nav-link">Consultas Medicas</a></li>
                            </ul>
                        </li-->
                        <!--li class="nav-item dropdown"  >
                            <a class="nav-link dropdown-toggle" a href="#departments">Especialidades</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a class="dropdown-item" href="#appointment" class="nav-link" >Citas</a></li>                                
                                <li class="nav-item"><a class="dropdown-item" href="#services" class="nav-link">Consultas Medicas</a></li>
                            </ul>
                        </li-->
                    </ul>



                    <div class="col-sm-3 col-lg-3">
                        <div class="header-top-item">
                            <div class="header-top-right">
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>