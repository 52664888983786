
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="assets/img/home-one/home-bg4.jpg" alt="About">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="About">
                    <h2>About Our Hospital</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                    <ul>
                        <li><i class="icofont-check-circled"></i> Browse Our Website</li>
                        <li><i class="icofont-check-circled"></i> Choose Service</li>
                        <li><i class="icofont-check-circled"></i> Send Messege</li>
                    </ul>
                    <a routerLink="/about">Know More</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area counter-bg counter-area-four">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    
                    <i class="icofont-patient-bed"></i>
                    <h3 class="counter">850</h3>
                    <p>Patients Beds</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-people"></i>
                    <h3><span class="counter">25000</span>+</h3>
                    <p>Happy Patients</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-doctor-alt"></i>
                    <h3 class="counter">750</h3>
                    <p>Doctors  & Nurse</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-badge"></i>
                    <h3 class="counter">18</h3>
                    <p>Year Experience</p>
                </div>
            </div>
        </div>
    </div>
</div>

<!--section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-7">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Speciality</span>
                        <h2>Our Expertise</h2>
                    </div>

                    <div class="speciality-item">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Child Care</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>More Stuff</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Enough Lab</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>24 Hour Doctor</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right">
                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3>Emergency Call</h3>
                        <p>+07 554 332 322</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->

<section id="sec_01" class="services-area pb-70">
    <div class="container">
        <div class="section-title-two">
            <h2>Nuestros Servicios</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Expert Doctor</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Expert Doctor</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-prescription"></i>
                        <h3>Diagnosis</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-prescription"></i>
                        <h3>Diagnosis</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-patient-file"></i>
                        <h3>Pathology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-patient-file"></i>
                        <h3>Pathology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-tooth"></i>
                        <h3>Dental Care</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-tooth"></i>
                        <h3>Dental Care</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonial-area ptb-100">
    <div class="container">
        <div class="testimonial-wrap">
            <h2>What our patient say</h2>
            
            <div class="testimonial-slider owl-theme owl-carousel">
                <div class="testimonial-item">
                    <img src="assets/img/home-three/7.png" alt="Testimonial">
                    <h3>John Mic</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam id labore vitae cupiditate dolorum voluptates dicta delectus quibusdam excepturi, veniam deserunt magni ipsam mollitia optio, quas quis rem non reprehenderit.</p>
                </div>

                <div class="testimonial-item">
                    <img src="assets/img/home-three/8.png" alt="Testimonial">
                    <h3>Adam Smith</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam id labore vitae cupiditate dolorum voluptates dicta delectus quibusdam excepturi, veniam deserunt magni ipsam mollitia optio, quas quis rem non reprehenderit.</p>
                </div>

                <div class="testimonial-item">
                    <img src="assets/img/home-three/9.png" alt="Testimonial">
                    <h3>Jac Jacson</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam id labore vitae cupiditate dolorum voluptates dicta delectus quibusdam excepturi, veniam deserunt magni ipsam mollitia optio, quas quis rem non reprehenderit.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title-two">
            <span>Blogs</span>
            <h2>Ultimas noticias</h2>
        </div>
        
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg11.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">In this hospital there are .</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>