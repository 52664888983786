<div class="page-title-area page-title-services">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Ecografía</h2>
                <ul>
                    <li><a routerLink="/">Inicio</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Servicios</li>
                </ul>
            </div>
        </div>
    </div>
</div>


<div class="container">
    <div class="row">

        
            <div class="other-details-item">
                <h1>Servicios de Ecografía</h1>
                <p>En nuestro centro médico ofrecemos servicios de ecografía de alta calidad, que permiten la evaluación y 
                    diagnóstico de diferentes condiciones de salud. La ecografía es un procedimiento seguro y no invasivo que 
                    utiliza ondas de ultrasonido para crear imágenes detalladas de los órganos y tejidos internos del cuerpo.
                </p>
                <span></span>
                <h2>Tipos de Ecografía que Realizamos:</h2>
                <ul>
                    <li>Ecografía Abdominal: Para evaluar órganos como el hígado, vesícula biliar, riñones, páncreas y bazo.</li>
                    <li>Ecografía Obstétrica: Seguimiento del desarrollo del bebé durante el embarazo.</li>
                    <li>Ecografía Pélvica: Para examinar órganos reproductores femeninos, como el útero y los ovarios, o la próstata en los hombres.</li>
                    <li>Ecografía Renal: Para diagnosticar problemas en los riñones.</li>
                    <li>Ecografía de Tiroides: Para evaluar el tamaño y la estructura de la glándula tiroides.</li>
                    <li>Ecocardiograma: Para evaluar la función del corazón.</li>
                </ul>
                <h2>Consejos para Realizarse una Ecografía</h2>
                <h2>Ecografía Abdominal</h2>
                <ul>
                    <li>Ayuno: Se recomienda un ayuno de 6 a 8 horas antes de la prueba para evitar la interferencia del contenido del estómago y los intestinos en las imágenes.</li>
                    <li>Hidratación: En algunos casos, puede ser necesario beber agua antes de la ecografía para obtener imágenes claras de ciertos órganos.</li>
                    <li>Ecografía Pélvica:</li>
                    <li>Vejiga Llena: Para obtener una imagen clara, es recomendable llegar con la vejiga llena, por lo que se sugiere beber agua y no orinar antes de la prueba.</li>
                </ul>
                <h2>Ecografía Obstétrica</h2>
                <ul>
                    <li>Sin Requisitos de Ayuno: No se requiere ayuno, pero se recomienda beber agua antes del examen si es una ecografía temprana para obtener imágenes más nítidas.</li>
                </ul>
                <h2>Ecocardiograma</h2>
                <ul>
                    <li>Sin Preparación Especial: Generalmente, no se requiere preparación especial para este tipo de ecografía.</li>
                </ul>
            </div>

    </div>
</div>