<!--div class="page-title-area page-title-five">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Contactanos</h2>
                <ul>
                    <li><a routerLink="/">Inicio</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Contactanos</li>
                </ul>
            </div>
        </div>
    </div>
</div-->

<!--div class="location-area">
    <div class="container">
        <div class="row location-wrap">
            <div class="col-sm-6 col-lg-4">
                <div class="location-item">
                    <i class="icofont-location-pin"></i>
                    <h3>Location</h3>
                    <p>2108-267 Road Quadra,Toronto, Victiria Canada</p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="location-item">
                    <i class="icofont-ui-call"></i>
                    <h3>Phone</h3>
                    <ul>
                        <li>+07 5554 3332 322</li>
                        <li>+07 5554 3332 322</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div-->
<!--div class="counter-area">
    <div class="container">
        <div class="row counter-bg">
            <div class="col-sm-6 col-lg-3">
                <div class="emergency-item">
                    <i class="icofont-ui-call"></i>
                    <div class="emergency-inner">
                        <h3>Telefono</h3>
                        <p>+593 993112438</p>
                        <p>+07 5554 3332 322</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6  col-lg-3">
                <div class="emergency-item">
                    <i class="icofont-location-pin"></i>
                    <div class="emergency-inner">
                        <h3>Ubicación</h3>
                        <p>9 de Octubre entre 10 Agosto y Comercio, Santa Elena, Ecuador</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="emergency-item">
                    <i class="icofont-email"></i>
                    <div class="emergency-inner">
                        <h3>Email</h3>
                        <p>info@cemas.ec</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6  col-lg-3">
                <div class="emergency-item">
                    <i class="icofont-ui-clock"></i>
                    <div class="emergency-inner">
                        <h3>Horarios</h3>
                        <p>Lunes - Viernes: 07:00 - 19:00</p>
                        <p>Sabados: 07:00 - 17:00</p>
                    </div>
                </div>
            </div>         

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="emergency-item">
                    <i class="icofont-ambulance-crescent"></i>
                    <div class="emergency-inner">
                        <h3>Ambulance</h3>
                        <p>+07 5554 3332 322</p>
                        <p>+07 5554 3332 322</p>
                    </div>
                </div>
            </div> 

        </div>
    </div>
</div!-->

<section class="drop-area pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 p-0">
                <div class="drop-item drop-img">

                    <form id="form" (submit)="sendEmail($event)">
                    
                    </form>
                    <div class="drop-left">
                        <h2>Deja tu mensaje para cualquier información o pregunta.</h2>

                        <form id="form" (submit)="sendEmail($event)">
                            <div class="row">
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input type="text" name="name" class="form-control" placeholder="Nombre">
                                    </div>
                                </div>
    
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input type="text" id="email" name="email" class="form-control" placeholder="Tu Email">
                                    </div>
                                </div>
    
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" class="form-control" placeholder="Tu Celular">
                                    </div>
                                </div>
    
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input type="text" name="subject" class="form-control" placeholder="Asunto">
                                    </div>
                                </div>
    
                                <div class="col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" cols="30" rows="5" placeholder="Tu Mensaje"></textarea>
                                    </div>
                                </div>
    
                                <div class="col-md-12 col-lg-12">
                                    <!--button type="submit" class="drop-btn">Enviar</button-->
                                    <input type="submit" id="button" class="btn btn-success" value="Enviar mensaje" >
                                </div>
                            </div>
                        </form>

                    </div>

                </div>
            </div>

            <div class="col-lg-5 p-0">
                <div class="speciality-item speciality-right speciality-right-two speciality-right-three">
                    <img src="assets/img/nosotros/bg4.jpg" alt="Contact">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3>Call Center </h3>
                        <p>+593 993112438</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="contact-map-area">
    <div id="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1993.4039735915178!2d-80.860625061547!3d-2.2258635994386107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902e0983bb61851f%3A0x292983c7854f577c!2sCEMAS%20-%20Centro%20de%20especialidades%20m%C3%A9dicas!5e0!3m2!1ses!2sec!4v1686887702821!5m2!1ses!2sec" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</div>


